<template>
  <div class="wrapper">
    <div class="travel-guide">

      <loader v-if="LOADER" />

      <div
        class="card"
        v-for="(item, index) in PLACES"
        :key="index"
      >
        <object-card
          type="icon"
          width="488px"
          height="275px"
          :img="item.images[Object.keys(item.images)[0]]"
          :title="item.title"
          :category="item.type.name"
          :icon="item.type.image"
          :item="item"
          @click.native="goToObject(item)"
        />
      </div>
      <span
        class="notification"
        v-if="NOTIFICATION"
      >
        {{ NOTIFICATION }}
      </span>
    </div>

    <div class="tags">
      <swiper
        :options="{
          slidesPerView: 'auto',
          touchRatio: 2,
          freeMode: true
        }"
      >
        <swiper-slide
          v-for="(tag, index) in filterTypes"
          :key="index"
        >
          <tag
            :title="tag.name"
            :active="tag.id === activeBadge.id"
            @click.native="changeBadge(tag)"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ObjectCard from "@/components/Parts/ObjectCard";
import Tag from "@/components/Parts/Tag";
import Loader from "@/components/Parts/Loader";

export default {
  components: {
    ObjectCard,
    Tag,
    Loader
  },

  data() {
    return {
      activeBadge: {
        name: "Все",
        id: 0,
      },
      count: 6  ,
      page: 1
    }
  },

  mounted() {
    this.GET_PLACES({
      page: this.page,
      count: this.count
    });
    this.GET_TYPES();
    this.scroll();
  },

  methods: {
    ...mapActions([
      "GET_PLACES",
      "GET_TYPES",
      "GET_TRIP_OR_PLACE",
      "GET_PLACES_TYPE"
    ]),

    ...mapMutations([
      "SET_CLEAR_PLACES"
    ]),

    scroll() {
      const elementContainer = document.querySelector(".travel-guide");

      elementContainer.addEventListener("scroll", () => {
        let scrollHeight = elementContainer.scrollHeight;
        if (elementContainer.scrollTop + elementContainer.clientHeight >= scrollHeight) {
          this.page = this.page + 1;
          if (this.activeBadge.id === 0) {
            this.GET_PLACES({
              count: this.count,
              page: this.page
            })
          } else {
            this.GET_PLACES_TYPE({
              object: this.activeBadge,
              count: this.count,
              page: this.page
            })
          }
        }
      })

    },

    goToObject(object) {
      this.GET_TRIP_OR_PLACE(object);
      this.$router.push({ path: `/object/${object.id}`,
        query: {
          object: object,
          title: "Рядом находится"
        }
      });
    },

    changeBadge(tag) {
      this.activeBadge = tag;
      this.page = 1;
      this.SET_CLEAR_PLACES();
      if (this.activeBadge.id === 0) {
        this.GET_PLACES({
          count: this.count,
          page: this.page
        })
      }
      this.GET_PLACES_TYPE({
        object: this.activeBadge,
        page: this.page,
        count: this.count
      });
    }
  },
  computed: {
    ...mapGetters(["PLACES", "TYPES", "LOADER", "NOTIFICATION"]),

    filterTypes() {
      let arr = [
        {
          name: "Все",
          id: 0,
        },
      ]
      return arr.concat(this.TYPES);
    },
  },
  beforeDestroy() {
    this.SET_CLEAR_PLACES();
  }
}
</script>

<style lang="scss" scoped>

.travel-guide {
  position: realtive;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  height: 1540px;
  overflow-y: auto;

  .card {
    width: 488px;
    height: 389px;
    margin-bottom: 48px;
    margin: 24.5px;
  }

  .not-place {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 56px;
    text-transform: uppercase;
  }
}

.tags {
  position: absolute;
  bottom: 176px;
  left: 0px;
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(255, 255, 255, 0.16);
}

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 40px;
}
</style>
